import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

export const useNoResultActionUtils = (isButtonClicked = false) => {
  const router = useRouter();
  const [isNoResultCtaClicked, setIsNoResultCtaClicked] = useState(isButtonClicked);

  const goToHomePage = useCallback(async () => {
    setIsNoResultCtaClicked(true);
    await router.push('/');
  }, [router]);

  return {
    isProcessing: isNoResultCtaClicked,
    handleAction: goToHomePage,
  };
};
